<template>
  <div class="chessHand" style="padding-bottom: 20px">
    <div v-title data-title="每日存款加赠"></div>


    <div class="imgbox">
      <img :src="`${imgUrl}pcbb/bg-DailydepositDiscounts.png`" />
    </div>

    <div class="rule-big-box">
      <div class="rule-top-img">
        <img src="../assets/img/pcbb/rules_1.png" v-if="cueTheme == 'dattheme'" />
        <img :src="`${imgUrl}pcbb/rules.png`" v-else />
      </div>
      <div class="rule-middle-img">
        <!-- 活动条件 -->
        <div class="rule-condition">
          <div class="rule-condition-title"></div>
          <div class="rule-acontents">
            <div class="title-item">
              <p>指定场馆:</p>
              <span style="color:var(--li-colortext);">任何游戏场馆</span>
            </div>
            <div class="title-item">
              <p>活动时间:</p>
              <span style="color:var(--li-colortext);">{{ datalist.date }}</span>
            </div>
            <div class="title-item">
              <p>领取时间:</p>
              <span style="color:var(--li-colortext);">{{ datalist.remark }}</span>
            </div>
            <div class="title-item">
              <p>参与方式:</p>
              <span style="color:var(--li-colortext);">实名会员账号≥3天，即可参加每日存款优惠的活动。</span>
            </div>
          </div>
        </div>

        <!-- 活动内容 -->
        <div class="rule-content">
          <div class="rule-content-title"></div>
          <table>
            <thead>
              <tr style="background: linear-gradient(90deg, #4581fa, #63b3fc);">
                <th
                  style="color: #fff;border-right: 1px solid #e2d2bf;font-family: PingFang SC;font-weight: 400;border-top-left-radius: 10px;">
                  您的当日最大1笔存款
                </th>
                <th
                  style="color: #fff;border-right: 1px solid #e2d2bf;font-family: PingFang SC;font-weight: 400;border-top-right-radius: 10px">
                  您的当前等级
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ datalist.dpt_money }}元</td>
                <td>{{ datalist.now_level }}</td>
              </tr>
            </tbody>
          </table>


          <table>
            <thead>
              <tr style="background: linear-gradient(90deg, #4581fa, #63b3fc);">
                <th
                  style="color: #fff;border-right: 1px solid #e2d2bf;font-family: PingFang SC;font-weight: 400;border-top-left-radius: 10px;font-size: 12px;">
                  等级
                </th>
                <th
                  style="color: #fff;border-right: 1px solid #e2d2bf;font-family: PingFang SC;font-weight: 400;font-size: 12px;">
                  存款优惠
                </th>
                <th
                  style="color: #fff;border-right: 1px solid #e2d2bf;font-family: PingFang SC;font-weight: 400;font-size: 12px;">
                  最高赠送彩金
                </th>
                <th style="color: #fff; border-top-right-radius: 10px;font-size: 12px;">
                  流水倍数
                </th>
              </tr>
            </thead>
            <tbody v-if="datalist.config">
              <tr v-for="(item, index) in datalist.config" :key="index">
                <td>{{ item.dpt_money }}</td>
                <td>{{ item.dpt_bouns }}</td>
                <td>{{ item.dpt_bouns2 }}</td>
                <td v-if="index == 0" :rowspan="datalist.config.length">{{ item.dpt_wlimit }}倍</td>
              </tr>
            </tbody>
          </table>

          <div class="btn-box">
            <div @click="applyWay">立即领取</div>
          </div>

          <div class="rule-content-title4" style="margin-top: 0;"></div>
          <p style="font-size: 14px;text-align: center;margin-top: 10px;color:var(--td-colortext);">仅展示近10笔，最长保留30天</p>

          <table>
            <thead>
              <tr style="background: linear-gradient(90deg, #4581fa, #63b3fc);">
                <th
                  style="color: #fff;border-right: 1px solid #e2d2bf;font-family: PingFang SC;font-weight: 400;border-top-left-radius: 10px;">
                  时间
                </th>
                <th style="color: #fff;border-right: 1px solid #e2d2bf;font-family: PingFang SC;font-weight: 400;">
                  红利
                </th>
                <th style="color: #fff; border-top-right-radius: 10px">
                  状态
                </th>
              </tr>
            </thead>
            <tbody v-if="datalist.apply_data">
              <tr v-for="(item, index) in datalist.apply_data" :key="index">
                <td>{{ item.created_at }}</td>
                <td>{{ item.bouns_amout }}</td>
                <td>{{ item.status == 0 ? '审核中' : item.remark }}</td>
              </tr>
            </tbody>
          </table>


        </div>

        <!-- 活动详情 -->
        <div class="rule-detail">
          <div class="rule-detail-title"></div>
          <div class="activity_rules">
            <ul>
              <li style="color: red;">
                <span>1.</span>
                实名账号≥3天，即可享受每日存款优惠活动。
              </li>
              <li>
                <span>2.</span>
                此活动会员每日可申请一次，仅限存款后投注前申请，优惠彩金未到账前请勿进行投注。此红利活动不得与其它需要本金申请的活动同时使用。
              </li>
              <li>
                <span>3.</span>
                领取此活动后，当天不能再对其它优惠等活动再次领取。
              </li>
              <li>
                <span>4.</span>
                每逢{{ datalist.remark }}，北京时间当天（0:00:00～23:59:59）取最大1笔存款作为申请活动的本金，每天只可申请一次。【彩金x6倍流水】即可提款。<br />
                计算公式如下： <br />
                例如：会员V6当天有2笔存款，第1笔20,000元（虚拟币存入），第2笔1,000元（EBpay存入）。<br />
                （取当天存款最大1笔）20,000 * 2%=赠送彩金400元，有效投注额为：400×6=2400
              </li>
              <li>
                <span>5.</span>
                在申请此优惠前，请您先完善真实姓名、手机号码、银行卡等个人信息。
              </li>
              <li>
                <span>6.</span>
                若发现有套利客户，对冲或不诚实获取盈利之行为，将取消其优惠资格。
              </li>
              <li>
                <span>7.</span>
                本活动只针对娱乐性质的会员，同一手机号码、电子邮箱、相同银行卡、同一个IP地址、同一通信地址、同一台设备，只能由一位会员使用，若发现重复行为，将无限期保留审核、扣回礼品及所产生的利润之权利。
              </li>
              <li>
                <span>8.</span>
                本活动中任何用户或团体以不正常的方式套取活动优惠，我司保留在不通知的情况下冻结或关闭账号使用的权力，并不退还款项，且用户会被列入黑名单。
              </li>
              <li>
                <span>9.</span>
                为避免文字争议，此活动遵循一般活动规则与条款，并由贝宝保留最终解释权。
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="rule-bottom-img"></div>
    </div>

    <van-popup v-model="showPicker" round position="bottom">
      <van-picker title="请选择场馆" show-toolbar value-key="name" :columns="datalist.youxi" @confirm="confirm"
        @cancel="cancel" />
    </van-popup>
  </div>
</template>

<script>
import { getdata } from '../network/api'
import { Toast } from 'vant'
export default {
  data () {
    return {
      datalist: [],
      isshow: false,
      timer: null,
      is_token: false,
      aa: { title: 'uni' },
      cueTheme: '',
      showPicker: false,
    }
  },
  onShow () { },

  mounted () {
    let that = this
    if (localStorage.getItem('access_token')) {
      this.is_token = true
    } else {
      this.is_token = false
    }
    this.getdata()
    this.cueTheme = window.localStorage.getItem('cueTheme')

    if (window.localStorage.getItem('opentype') == 'h5') {
      window.addEventListener('message', function (e) {
        // 数据返回
        if (e.data.act == 'YdDptBonus') {
          if (e.data.status == 1) {
            that.getdata()
          }
        }
      })
    }
  },

  methods: {
    gotoback () {
      if (window.localStorage.getItem('opentype') == 'h5') {
        uni.navigateBack({})
      } else {
        uni.getEnv((res) => {
          uni.postMessage({
            data: {
              isclose: 'true',
            },
          })
        })
      }
    },

    getdata () {
      var _this = this
      var data = '?type=YdDptBonus'
      getdata(data).then((res) => {
        this.datalist = res.message
      })
    },
    changeisshow () {
      clearInterval(this.timer)
      this.isshow = true
      this.timer = setTimeout(() => {
        this.isshow = false
      }, 3000)
    },
    applyWay () {
      uni.getEnv((res) => {
        if (Object.keys(res)[0] === 'h5') {
          // Toast('该活动仅在app开发,请下载APP')
          window.parent.postMessage(
            {
              status: 'ok',
              params: {
                urlApply: 'vipActapplyobject',
                actobject: {
                  act: 'YdDptBonus',
                },
                urlType: window.localStorage.getItem('urlType'),
              },
            },
            '*'
          )
        } else {
          // 申请
          // this.showPicker = true
          // uni.postMessage({
          //   data: {
          //     urlApply: 'vipActapplyobject',
          //     actobject: {
          //         act: 'YdDptBonus',
          //     },
          //   }
          // })
          uni.postMessage({
            data: {
              urlApply: 'vipActapply',
              actname: 'YdDptBonus',
            }
          })
        }
      })
    },
    confirm (e) {
      uni.postMessage({
        data: {
          urlApply: 'vipActapply',
          actname: 'YdDptBonus',
          gameid: e.id
        }
      })
      this.showPicker = false
    },
    cancel () {
      this.showPicker = false
    }
  },
}
</script>

<style lang="less">
@import '../assets/css/rulestyle.less';

body {
  background-color: #f7f8fa;
}

.van-icon .van-icon-arrow-left .van-nav-bar__arrow {
  color: #000 !important;
}

.van-nav-bar .van-icon {
  color: #000;
}

.chessHand {
  position: relative;
  font-family: PingFang SC;

  .btn-box {
    padding-bottom: 5px;

    >div {
      font-family: PingFang SC;
      font-weight: 400;

      &:nth-child(1) {
        height: 40px;
        color: #fff;
        line-height: 40px;
        text-align: center;
        border-radius: 5px;
        background: linear-gradient(0deg, #2a6afd, #63c6ff);
        margin: 25px 0px 15px 0px;
      }

      &:nth-child(2) {
        height: 40px;
        line-height: 40px;
        text-align: center;
        border-radius: 5px;
        border: 1px solid #2a6afd;
        color: #418beb;
        margin: 15px 0px 15px 0px;
      }
    }
  }

  .rightbox {
    position: fixed;
    top: 150px;
    right: 0;
    z-index: 1;
    background: linear-gradient(0deg, #2a6afd, #63c6ff);
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    padding: 5px 5px 5px 0;
    color: #fff;

    .content {
      display: flex;
      align-items: center;

      >div {
        &:nth-child(1) {
          min-width: 54px;
          height: 54px;
          border: 4px solid #fff;
          border-radius: 50%;
          margin-left: 5px;
          margin-right: 5px;

          img {
            width: 45px;
            height: 45px;
            border-radius: 50%;
          }
        }

        .primary_color {
          font-size: 11px;
          line-height: 1.2em;
        }
      }
    }
  }
}
</style>
